import React from "react";
import HeaderDivideTextLeft from "../components/headerDivideTextLeft";
import Layout from "../components/layout";
import Row from "starkeydigital/React/Components/row";
import Button from "starkeydigital/React/Components/button";
interface props {}
const Contact: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Contact us."
      titleText2=""
      slogan="We are always delighted to understand your needs and to speak to you about our work."
      includeRandomTestimonial={false}
      includeContact={false}
      includeTestimonials={true}
      testimonialsBackground="#FCFCFD"
    >
      <div id="contact-form">
        <HeaderDivideTextLeft
          style={{marginTop: "115px"}}
          header="Let's talk"
          column="col-md-10"
          text="If you would like to learn more about our leadership and coaching programmes or have any
        questions, please fill out the form below and we'd be delighted to hear from you. Alternatively, you can call us on 07415 106135 or email us at  <span style='text-transform:lowercase;'>enquiries@atdpartners.co.uk</span>"
        />

        <form>
          <Row style={{marginTop: "50px"}}>
            <div className="col-md-5">
              <div className="input">
                <label>Name*</label>
                <input
                  type="text"
                  name="name"
                  placeholder="What’s your name?"
                />
              </div>
            </div>
            <div className="col-md-5 mt-md-0 mt-3">
              <div className="input">
                <label>Email*</label>
                <input
                  type="email"
                  name="email"
                  placeholder="What’s your email address?"
                />
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-md-5">
              <div className="input">
                <label>Company name</label>
                <input
                  type="text"
                  name="company-name"
                  placeholder="What’s your company name?"
                />
              </div>
            </div>
            <div className="col-md-5 mt-md-0 mt-3">
              <div className="input">
                <label>Phone Number*</label>
                <input
                  type="tel"
                  name="telephone"
                  placeholder="What’s your phone number?"
                />
              </div>
            </div>
          </Row>

          <Row>
            <div className="col-md-10">
              <div className="input">
                <label>Details & questions about your project</label>
                <textarea
                  name="message"
                  placeholder="Any extra info you’d like to give us to get us started?"
                />
              </div>
            </div>
          </Row>

          <p>* These fields must be completed</p>
          <Button color="secondary">Send your message</Button>
        </form>
      </div>
    </Layout>
  );
};
export default Contact;
